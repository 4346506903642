import React from 'react'
import TutorialButton from '../components/ui/button/TutorialButton/TutorialButton'

import './AuthLayout.scss'

const AuthLayout = ({ children }) => {
  return (
      <main className="secured-main">
        <TutorialButton />
        <div>{children}</div>
      </main>
  )
}

export default AuthLayout
