import { Modal } from 'antd'

import TutorialListDrawer from '../../drawer/TutorialListDrawer/TutorialListDrawer'
import TutorialVideoPlayer from '../../VideoPlayer/TutorialVideoPlayer/TutorialVideoPlayer'

import { useTutorialOverlay } from '../../../../hooks/ui/useTutorialOverlay/useTutorialOverlay'

import './TutorialOverlay.scss'

const TutorialOverlay = ({ isModalOpen, handleOnCancel, tutorials = [] }) => {
  const { selectTutorial, selectedTutorial, unselectTutorial } = useTutorialOverlay()
  const onCancel = () => {
    unselectTutorial()
    handleOnCancel()
  }

  return (
    <>
      <Modal
        visible={isModalOpen}
        open={true}
        footer={null}
        centered
        onCancel={onCancel}
      >
        <TutorialVideoPlayer selectedTutorial={selectedTutorial} />
      </Modal>
      <TutorialListDrawer
        isDrawerVisible={isModalOpen}
        tutorials={tutorials}
        onClickTutorial={selectTutorial}
        selectedTutorial={selectedTutorial}
        onClickBack={onCancel}
      />
    </>
  )
}
export default TutorialOverlay
