export const useTutorialButton = () => {
  const isTutorialEmpty = (tutorials) => {
    return tutorials.length === 0
  }

  const showTutorialButton = (currentUrl) => {
    return !currentUrl.includes('secured/login')
  }

  return { isTutorialEmpty, showTutorialButton }
}
