// TODO: Implement video player

const TutorialVideoPlayer = ({selectedTutorial}) => {
  return(
    <div>
      {selectedTutorial ? (
        <img src="https://placehold.co/911x506" alt={selectedTutorial.title} />
      ) : (
        <img src="https://placehold.co/911x506" alt="tutorial video" />
      )}
    </div>
  )
}

export default TutorialVideoPlayer
